import React, { Fragment } from "react";
import {
  Link as MuiLink,
  Box,
} from "@mui/material";
import { ThemeProvider, alpha, lighten } from "@mui/material/styles";
import theme from "./theme.js";
import { Route, Routes, Link, BrowserRouter } from "react-router-dom";
import Home from "./Home.jsx";
import Privacy from "./Privacy.jsx";

const footerItemStyle = {
  color: lighten(theme.palette.text.primary, 0.3),
  textDecoration: "none",
  mx: "20px",
  "&:hover": {
    color: "text.primary",
  },
};

const footerItemHoverStyle = {
  ...footerItemStyle,
  "&:hover": {
    color: "text.primary",
  },
};

function App() {
  return (
      <BrowserRouter>
          <ThemeProvider theme={theme}>
              <Fragment>
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/privacy" element={<Privacy />} />
                      
                  </Routes>
                  <footer>
                      <Box
                          sx={{
                              borderTop:
                                  "1px solid " +
                                  alpha(theme.palette.gray.main, 0.8),
                          }}
                          p="10px"
                      >
                          <Box display="flex"
                            flexDirection="column"
                            width="auto"
                            justifyContent="space-around"
                            alignItems="center"
                            flexWrap="wrap">
                              <Box textAlign="center" sx={footerItemStyle}  my="5px">
                                  Copyright © 2023 Azone
                              </Box>
                              <MuiLink
                                  sx={footerItemHoverStyle}
                                  href="/privacy"
                                  my="5px"
                              >
                                  Privacy Policy
                              </MuiLink>
                              {/* <MuiLink sx={footerItemHoverStyle} href="/terms"  my="5px">
                                  Terms of Service
                              </MuiLink> */}
                              <MuiLink
                                  sx={footerItemHoverStyle}
                                  mx="20px"
                                  href="mailto:support@azone.app"
                                  my="5px"
                              >
                                  Contact
                              </MuiLink>
                          </Box>
                      </Box>
                  </footer>
              </Fragment>
          </ThemeProvider>
      </BrowserRouter>
  );
}

export default App;

import { Fragment } from "react";
import * as React from "react";
import {
  Typography,
  Box,
  Button,
  Avatar,
  Link as MuiLink,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ThemeProvider, darken, styled, lighten } from "@mui/material/styles";
import theme from "./theme.js";
import { redirect, useSearchParams, useLocation } from "react-router-dom";


class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <Box
            bgcolor="background.default"
            border="hidden"
            py="10px"
            px="2%"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <h1
              style={{
                fontSize: "2rem",
                fontWeight: "600",
                lineHeight: "1rem",
                color: theme.palette.secondary.main,
                textAlign: "center",
                width: "100%",
                fontFamily: "Arial, sans-serif",
              }}
            >
              OpenX
            </h1>
            <h2
              style={{
                fontSize: "1.5rem",
                fontWeight: "400",
                lineHeight: "1.5rem",
                color: theme.palette.secondary.main,
                textAlign: "center",
                width: "100%",
                fontFamily: "Arial, sans-serif",
              }}
            >
              An easy-to-use and fast native client for ChatGPT
            </h2>
            <h3
              style={{
                fontSize: "1rem",
                fontWeight: "200",
                lineHeight: "1.2rem",
                color: "#808080",
                textAlign: "center",
                width: "100%",
                fontFamily: "Arial, sans-serif",
              }}
            >
              OpenX is an iOS app that serves as a third-party client for ChatGPT. With a clean and intuitive interface, users can chat with ChatGPT, ask questions, and receive personalized responses. The app integrates seamlessly with ChatGPT and offers advanced customization options, all while maintaining user privacy and security.
            </h3>
            <Box mx="1px" mt="1px">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                my="10px"
              >
                <Box
                  maxWidth="90%"
                  minWidth="200px"
                  height="auto"
                  minHeight="200px"
                  component="img"
                  src={`${process.env.PUBLIC_URL}/images/iphone1.png`}
                  boxShadow="0 12px 50px 12px rgb(77 77 77 / 50%)"
                  margin="10px"
                ></Box>
                <Box
                  maxWidth="90%"
                  minWidth="200px"
                  height="auto"
                  minHeight="200px"
                  component="img"
                  src={`${process.env.PUBLIC_URL}/images/iphone2.png`}
                  boxShadow="0 12px 50px 12px rgb(77 77 77 / 50%)"
                  margin="10px"
                ></Box>
                <Box
                  maxWidth="90%"
                  minWidth="200px"
                  height="auto"
                  minHeight="200px"
                  component="img"
                  src={`${process.env.PUBLIC_URL}/images/iphone3.png`}
                  boxShadow="0 12px 50px 12px rgb(77 77 77 / 50%)"
                  margin="10px"
                ></Box>
              </Box>
            </Box>
          </Box>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Home;

import {
  createTheme,
  responsiveFontSizes,
  darken,
  lighten,
} from "@mui/material/styles";

// colors
const primary = "#fff";
const secondary = "#01adb5";
const secondaryDark = darken(secondary, 0.1);
const background = "#f1f8fe";
const textPrimary = "#141b32";
const textSecondary = "#f7f8fc";
const gray = "#e6e6e6";
const grayDark = darken(gray, 0.1);
const grayLight = lighten(gray, 0.1);

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary, dark: secondaryDark },
    background: { default: background },
    text: { primary: textPrimary, secondary: textSecondary },
    gray: { main: gray, dark: grayDark, light: grayLight },
    spacing,
  },
  typography: {
    fontFamily: "Arial, sans-serif",
    h1: {
      fontSize: "60px",
      fontWeight: 700,
      display: "block",
    },
    h2: {
      fontSize: "45px",
      fontWeight: 600,
      display: "block",
    },
    h3: {
      fontSize: "32px",
      fontWeight: 600,
      display: "block",
    },
    h4: {
      fontSize: "24px",
      fontWeight: 400,
      display: "block",
    },
    subtitle1: {
      fontSize: "64px",
      fontWeight: 600,
      display: "block",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 500,
      display: "block",
    },
  },
  breakpoints: {
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
});

export default responsiveFontSizes(theme);

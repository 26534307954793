import React from "react";
import theme from "./theme.js";

export default function Privacy() {
  return (
    <div
      style={{
        background: theme.palette.background.default,
        padding: "20px",
      }}
    >
      <div
        style={{
          maxWidth: "1000px",
          margin: "auto",
        }}
      >
        <h1>PRIVACY NOTICE</h1>
        <strong>Last updated April 26, 2022</strong>
        <p>
          Azone ("us", "we", or "our") operates the OpenX
          app (the "Service").
        </p>
        <p>
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
        </p>
        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy.
        </p>
        <h2>Information Collection And Use</h2>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h3>Types of Data Collected</h3>
        <h4>Usage Data</h4>
        <p>
          We may also collect anonymous information (non identifiable) how the
          Service is accessed and used ("Usage Data"). This Usage Data may
          include information such as your computer's Internet Protocol address
          (e.g. IP address), browser type, browser version, the pages of our
          Service that you visit, the time and date of your visit, the time
          spent on those pages, unique device identifiers and other diagnostic
          data.
        </p>
        <h4>Tracking & Cookies Data</h4>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information.
        </p>
        <p>
          Cookies are files with small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Tracking technologies also used are
          beacons, tags, and scripts to collect and track information and to
          improve and analyze our Service.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </p>
        <p>Examples of Cookies we use:</p>
        <ul>
          <li>
            <strong>Session Cookies.</strong> We use Session Cookies to operate
            our Service.
          </li>
          <li>
            <strong>Preference Cookies.</strong> We use Preference Cookies to
            remember your preferences and various settings.
          </li>
          <li>
            <strong>Security Cookies.</strong> We use Security Cookies for
            security purposes.
          </li>
        </ul>
        <h2>Use of Data</h2>
        <p>Azone uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>

        <h2>Transfer Of Data</h2>
        <p>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>
          Azone will take all steps reasonably necessary to ensure that your
          data is treated securely and in accordance with this Privacy Policy
          and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
        <h2>Disclosure Of Data</h2>
        <h3>Legal Requirements</h3>
        <p>
          Azone may disclose your Personal Data in the good faith belief that
          such action is necessary to:
        </p>

        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Azone</li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>
        <h2>Data Storage & Security</h2>
        <p>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. We strive to use commercially acceptable means
          to protect your Personal Data. All data is encrypted at rest and
          transferred over SSL. We regularly back up data and have a recovery
          procedure to ensure business continuity.
        </p>
        <h2>Data Retention</h2>
        <p>
          We retain the minimum amount of information to provide the service.
          Data is stored as long as users continue to use our services. Users
          can opt to request data removal at any time.
        </p>
        <h2>Data Archival & Removal</h2>
        <p>
          All data is backed up daily and backups are kept for 30 days. After
          the uninstallation, the backups are automatically deleted in 7 days.
          Users can request data deletion by emailing support@azone.app, and we
          will delete all the information we have stored about the user in 2
          days.
        </p>
        <h2>Changes To This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          by email: support@azone.app.
        </p>
      </div>
    </div>
  );
}
